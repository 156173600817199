<template>
  <el-table :data="tableData">
    <el-table-column prop="date" label="账号" width="140"> </el-table-column>
    <el-table-column prop="name" label="设备" width="120"> </el-table-column>
    
    <el-table-column prop="address" label="角色名"> </el-table-column>
    <!-- <el-table-column prop="address" label="等级"> </el-table-column>
    <el-table-column prop="address" label="金币"> </el-table-column> -->
    
  </el-table>
</template>

<script>
export default {
  data() {
    const item = {
      date: "2016-05-02",
      name: "王小虎",
      address: "上海市普陀区金沙江路 1518 弄",
    };
    return {
      tableData: Array(20).fill(item),
    };
  },
};
</script>
